#toast-container {
  display:block;
  position: fixed;
  z-index: 10000;

  @media #{$small-and-down} {
    min-width: 100%;
    bottom: 0%;
  }
  @media #{$medium-only} {
    left: 5%;
    bottom: 7%;
    max-width: 90%;
  }
  @media #{$large-and-up} {
    top: 10%;
    right: 7%;
    max-width: 86%;
  }
}

.toast {
  @extend .z-depth-1;
  border-radius: 2px;
  top: 35px;
  width: auto;
  clear: both;
  margin-top: 10px;
  position: relative;
  max-width:100%;
  height: auto;
  min-height: $toast-height;
  line-height: 1.5em;
  word-break: break-all;
  background-color: $toast-color;
  padding: 10px 25px;
  font-size: 1.1rem;
  font-weight: 300;
  color: $toast-text-color;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn, .btn-flat {
    margin: 0;
    margin-left: 3rem;
  }

  &.rounded{
    border-radius: 24px;
  }

  @media #{$small-and-down} {
    width:100%;
    border-radius: 0;
  }
  @media #{$medium-only} {
    float: left;
  }
  @media #{$large-and-up} {
    float: right;
  }

}
