

.card-panel {
  transition: box-shadow .25s;
  padding: $card-padding;
  margin: $element-top-margin 0 $element-bottom-margin 0;
  border-radius: 2px;
  @extend .z-depth-1;
  background-color: $card-bg-color;
}

.card {
  position: relative;
  margin: $element-top-margin 0 $element-bottom-margin 0;
  background-color: $card-bg-color;
  transition: box-shadow .25s;
  border-radius: 2px;
  @extend .z-depth-1;


  .card-title {
    font-size: 24px;
    font-weight: 300;
    &.activator {
      cursor: pointer;
    }
  }

  // Card Sizes
  &.small, &.medium, &.large {
    position: relative;

    .card-image {
      max-height: 60%;
      overflow: hidden;
    }
    .card-image + .card-content {
      max-height: 40%;
    }
    .card-content {
      max-height: 100%;
      overflow: hidden;
    }
    .card-action {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &.small {
    height: 300px;
  }

  &.medium {
    height: 400px;
  }

  &.large {
    height: 500px;
  }

  // Horizontal Cards
  &.horizontal {
    &.small, &.medium, &.large {
      .card-image {
        height: 100%;
        max-height: none;
        overflow: visible;

        img {
          height: 100%;
        }
      }
    }

    display: flex;

    .card-image {
      max-width: 50%;
      img {
        border-radius: 2px 0 0 2px;
        max-width: 100%;
        width: auto;
      }
    }

    .card-stacked {
      display: flex;
      flex-direction: column;
      flex: 1;
      position: relative;

      .card-content {
        flex-grow: 1;
      }
    }
  }

  // Sticky Action Section
  &.sticky-action {
    .card-action {
      z-index: 2;
    }

    .card-reveal {
      z-index: 1;
      padding-bottom: 64px;
    }
  }




  .card-image {
    position: relative;

    // Image background for content
    img {
      display: block;
      border-radius: 2px 2px 0 0;
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
    }

    .card-title {
      color: $card-bg-color;
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 100%;
      padding: $card-padding;
    }
  }

  .card-content {
    padding: $card-padding;
    border-radius: 0 0 2px 2px;

    p {
      margin: 0;
      color: inherit;
    }
    .card-title {
      display: block;
      line-height: 32px;
      margin-bottom: 8px;

      i {
        line-height: 32px;
      }
    }
  }

  .card-action {
    &:last-child {
      border-radius: 0 0 2px 2px;
    }
    position: relative;
    background-color: inherit;
    border-top: 1px solid rgba(160,160,160,.2);
    padding: 16px $card-padding;

    a:not(.btn):not(.btn-large):not(.btn-floating) {
      color: $card-link-color;
      margin-right: $card-padding;
      transition: color .3s ease;
      text-transform: uppercase;

      &:hover { color: $card-link-color-light; }
    }
  }

  .card-reveal {
    padding: $card-padding;
    position: absolute;
    background-color: $card-bg-color;
    width: 100%;
    overflow-y: auto;
    left: 0;
    top: 100%;
    height: 100%;
    z-index: 3;
    display: none;

    .card-title {
      cursor: pointer;
      display: block;
    }
  }
}
